import React, { useEffect, useState } from "react";
import PageError from "./PageError.js";
import { MDBDatatable } from "mdb-react-ui-kit";
import { GetBussinessInfo } from "../services/apiServices.js";
import Loader from "../components/Loader.js";
import LeftPane from "../components/LeftPane.js";
import Header from "../components/Header.js";

function BussinessReport() {
    const [bussinessInfo, setbussinessInfo] = useState([])
    const [apiError, setApiError] = useState(false);
    const [DayFilter, setDayFilter] = useState("4");
    const [loading, setLoading] = useState(false)
    const [basicData, setBasicData] = useState({
        columns: [
            { label: 'S.no', field: 'Sno' },
            { label: 'Bussness Name', field: 'bussnessName' },
            { label: 'Email', field: 'email' },
            { label: 'Admin Name', field: 'adminName' },
            { label: 'created Date', field: 'createdDate' },
            { label: 'Active Member(s)', field: 'count' },
        ],
        rows: [],
    });


    async function fetchData() {
        setLoading(true)

        const response = await GetBussinessInfo(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
        );

        // console.log(response);
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
        } else {


            const record = await response.json();
            if (!record) {
                return;
            }

            console.log(record);
            setbussinessInfo(record);

        }


    }

    useEffect(() => {
        fetchData();
    }, [])



    console.log(basicData);
    console.log(bussinessInfo);

    useEffect(() => {
        const popupRow = [];
        bussinessInfo && bussinessInfo && bussinessInfo.length > 0 && bussinessInfo.forEach((item, index) => {
            const rowData = {
                Sno: index + 1,
                bussnessName: item.businessName,
                email: item.businessEmail,
                adminName: item.firstName ? item.firstName + " " + item.lastName : "",
                createdDate: <span className='trans-date'>{new Date(item.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>,
                count: item.activeMemberCount,
            };
            popupRow.push(rowData);
        });
        setBasicData(prevData => ({
            ...prevData,
            rows: popupRow
        }));
        console.log(popupRow);
        setLoading(false)

    }, [bussinessInfo]);


    const handleReload = () => {
        window.location.reload();
    }



    return (
        <>
            <LeftPane isactive="13" />
            <Header />
            {apiError ? <PageError handleReload={handleReload} /> :
                <div className="mt-4 rightContent">


                    <div className=" d-flex flex-wrap">
                 
                      
                      
                      
                    </div>
                    <div className="">
                        {loading ? (<div className="loader-height"> <Loader /></div>) :
                            <MDBDatatable
                                fixedHeader
                                search

                                maxHeight='calc(100vh - 223px)'
                                entries={25}
                                paging={true}
                                data={basicData}
                            // onRowClick={handleRowClick}
                            />
                        }
                    </div>
                </div>

            }

        </>
    )
}

export default BussinessReport